import request from "../utils/request";

//更新用户昵称
export const upName = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Equipment/upName",
        method: "post",
        data: query,
    });
};


//查询用户基本信息
export const finduserinfo = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Equipment/find/userinfo",
        method: "get",
        params: query,
    });
};


//查询设备基本信息
export const findequipment = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Equipment/find/equipment",
        method: "get",
        params: query,
    });
};



//新增维保数据
export const add_a = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Equipment/add_a",
        method: "post",
        data: query,
    });
};

//新增精准报价
export const add_b = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Equipment/add_b",
        method: "post",
        data: query,
    });
};

//新增设计资讯数据
export const add_c = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Equipment/add_c",
        method: "post",
        data: query,
    });
};
