<template>
  <div class="result" v-if="view">
    <div class="main">
      <div class="resultTop"></div>
      <div class="tol">{{ Tol_B }}元</div>
      <div class="i-block_1">您的实验室预算总价</div>
      <div class="i-block_2">
        <p><span class="span_1">实验台柜面积</span><span class="span_2">{{ area }}平方米</span></p>
        <p><span class="span_1">通风系统总价</span><span class="span_2">{{ b_2 }}元</span></p>
        <p><span class="span_1">安装实施费用</span><span class="span_2">{{ b_3 }}元</span></p>
      </div>
      <div class="i-block_3">该报价来自华盛系统估算，实际价格以量房为准。<br>该报价未包含：运费、智能系统、废气废液处理系统統等个性化需求。
      </div>
      <div class="i-block_4"> 联系我们的业务员，获取免费精准报价服务</div>
      <div class="exit" @click="exit"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      view: false,
      area: 0,
      cabinet: 0,
      b_1: 0,
      b_2: 0,
      b_3: 0,
      Tol_B: 0,
      areaData:''
    }
  },
  methods: {
    open(areaData,area, cabinet) {
      this.areaData=areaData
      this.view = true
      this.area = area
      this.cabinet = cabinet
      this.init()
    },
    init() {
      //实验台柜面积  一个柜台50平方米
      let b_1 = Number(this.area) * 50
      //通风系统总价  通风柜 一个2000元一个
      let b_2 = Number(this.cabinet) * 2000
      this.b_2 = b_2
      //安装实施费用  面积×250元一平方米
      let b_3 = b_1 * 250
      this.b_3 = b_3.toFixed(2)
      this.Tol_B = (b_2 + b_3).toFixed(2)

    },
    exit() {
      this.view = false
    }
  }
}
</script>


<style scoped lang="scss">
.result {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  z-index: 5;

  .main {
    width: 350px;
    height: 450px;
    background-image: url("https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2023-6-10/resultBg.png");
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -175px;
    margin-top: -225px;

    .resultTop {
      width: 120px;
      height: 100px;
      background-image: url("https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2023-6-10/resultTop.png");
      background-size: 100% 100%;
      position: absolute;
      margin-left: -60px;
      left: 50%;
      top: -40px;
    }

    .tol {
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      position: absolute;
      top: 80px;
    }

    .i-block_1 {
      color: #999999;
      position: absolute;
      width: 100%;
      text-align: center;
      top: 130px;
      font-size: 13px;
    }

    .i-block_2 {
      background-color: #f3f6fd;
      position: absolute;
      width: 300px;
      height: 150px;
      text-align: center;
      top: 170px;
      border-radius: 10px;
      left: 25px;

      p {
        width: 100%;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
      }

      .span_1 {
        color: #999999;
        margin-right: 10px;
      }

      .span_2 {
        color: #333333;
        font-weight: bold;
      }
    }

    .i-block_3 {
      position: absolute;
      text-align: center;
      top: 330px;
      font-size: 13px;
      color: #999999;
      width: 300px;
      left: 25px;
    }

    .i-block_4 {
      position: absolute;
      text-align: center;
      top: 400px;
      font-size: 13px;
      color: #3862FF;
      width: 300px;
      left: 25px;
    }

    .exit {
      width: 20px;
      height: 20px;
      background-image: url("../../../assets/image/exit-1.png");
      background-size: 100% 100%;
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
;
}
</style>