<template>
  <div class="block_1">
    <div class="coverImage">
      <img src="https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2023-6-10/home_bg_1.png" alt="">
    </div>

    <div class="main_1">
      <div class="title">
        <span>10秒内</span>获取报价<br><i></i>实验装修就这么简单<i style="margin-left: 10px;transform: scaleX(-1);"></i>
      </div>
      <div class="inputBlock" @click="openarea">
        {{ areaData }}
        <i class="icon"><img src="../../../assets/image/linke.png" alt=""/></i>
      </div>
      <div class="inputBlock">
        <input placeholder="请输入实验室面积" type="number" v-model="area"/>
        <div class="unit">平方</div>
      </div>
      <div class="inputBlock">
        <input placeholder="请输入通风柜数量" type="number" v-model="cabinet"/>
        <div class="unit">台</div>
      </div>
      <div class="demand"><i></i>点击报价即表示您同意《用户服务协议》《隐私政策》</div>
      <div class="bottonBg">
        <div class="button" @click="openBudget" :style="{'border':buttonSize,'width':width,'height':height,'line-height':height}">实验室预算报价
        </div>
      </div>

      <img src="https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2023-6-10/Home_1.png" alt="" class="content_bg">
      <div class="bottonBg">
        <div class="button_1" :style="{'border':buttonSize,'width':width,'height':height,'line-height':height}"  @click="opendesign">实验室设计资讯</div>
      </div>

      <img src="https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2023-6-10/Home_2.png" alt="" class="content_bg" style="margin-top:20px">
      <img src="https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2023-6-10/Home_3.png" alt="" class="content_bg">
    </div>
    <div class="in"></div>
    <result ref="result"></result>
    <design ref="design"></design>
    <div class="area" v-if="areaView">
      <van-area title="选择地址" :area-list="areaList" class="main" confirm-button-text="确定" @confirm="confirm"
                @cancel="cancel"/>
    </div>
  </div>
</template>

<script>
import {areaList} from "@vant/area-data";
import {Area, Dialog, Notify} from "vant";
import result from './result.vue'
import design from './design.vue'

export default {
  data() {
    return {
      areaView: false,
      areaList,
      areaData: '请选择地址',
      area: null,
      cabinet: null,
      buttonSize: null, // 初始
      width: null,
      height: null,
      resizeInterval: null, // 存储定时器
    }
  },
  beforeDestroy() {
    if (this.resizeInterval) {
      clearInterval(this.resizeInterval); // 清除定时器
    }
  },
  mounted() {
    this.startResize()
    // this.buttonSize='1px solid #FFFFFF'
  },
  methods: {
    startResize() {
      if (this.resizeInterval) return;
      this.resizeInterval = setInterval(() => {
        if (this.buttonSize) {
          this.buttonSize = null;
          this.width = null
          this.height = null
        } else {
          this.buttonSize = '2px solid #ffffff'
          this.width = '275px'
          this.height = '35px'
        }
      }, 500); // 每0.1秒调整一次大小
    },
    opendesign() {
      this.$refs.design.open()
    },
    openBudget() {
      let areaData = this.areaData
      let area = this.area
      let cabinet = this.cabinet
      if (areaData && area && cabinet) {
        this.$refs.result.open(areaData, area, cabinet)
      } else {
        Dialog.alert({
          message: '请输入实验室面积、通风柜、地址',
        });
      }
    },
    confirm(value) {
      let text = `${value[0].name}${value[1].name}${value[2].name}`
      this.areaData = removeDuplicates(text)
      this.cancel()

      function removeDuplicates(str) {
        let obj = {};
        for (let i = 0; i < str.length; i++) {
          if (str[i] in obj) {
            continue; // 如果字符已经在对象中，跳过
          }
          obj[str[i]] = true; // 将字符作为对象的属性名添加
        }
        return Object.keys(obj).join(''); // 将对象的属性名拼接成字符串返回
      }
    },
    cancel() {
      this.areaView = false
    },
    openarea() {
      this.areaView = true
    },
    openAi() {

    }
  },
  components: {
    [Area.name]: Area, result, [Notify.Component.name]:
    Notify.Component, design
  }
}
</script>


<style scoped lang="scss">
.block_1 {
  width: 100%;
  height: 2600px;
  background-image: url('https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2024-4-29/bg.png');
  background-size: 100%;
  box-sizing: border-box;
  background-color: #dae2ff;
  position: relative;
}

.coverImage {
  width: 100%;
  height: 200px;
  background-color: #ededed;

  img {
    width: 100%;
    height: 100%;
  }
}

.main_1 {
  width: 90%;
  height: 2400px;
  background-color: #FFFFFF;
  margin: 0 auto;
  margin-top: 30px;
  border-radius: 15px;
  padding: 30px 0;

  .title {
    width: 100%;
    height: 70px;
    line-height: 30px;
    text-align: center;
    font-size: 16px;

    span {
      font-size: 19px;
      color: #3862ff;
    }

    i {
      width: 10px;
      height: 10px;
      background-image: url("../../../assets/image/shape_1.png");
      background-size: 100% 100%;
      display: inline-block;
      margin-right: 10px;
    }
  }

  .inputBlock {
    width: 80%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    //text-align: center;
    border: 1px solid #ededed;
    border-radius: 5px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 20px;
    text-indent: 1rem;

    .icon {
      position: absolute;
      right: 30px;
      top: 3px;
      //transform: rotate(180deg);
      width: 15px;
      height: 15px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    input {
      position: absolute;
      left: 0;
      width: 80%;
      height: 100%;
      border: none;
      background: none;
      text-indent: 1rem;
      //color: #dcdcdc;
    }

    .unit {
      position: absolute;
      right: 10px;
      font-size: 12px;
    }
  }

  .demand {
    text-align: center;

    i {
      width: 10px;
      height: 10px;
      background-color: #b5b5b5;
      display: inline-block;
      margin-right: 5px;
      border-radius: 50px;
    }

    font-size: 12px;
  }

  .navTitle {
    font-size: 12px;
    font-weight: bold;
    color: #898989;
    margin-top: 50px;
    text-align: center;
  }

  .poster {
    width: 70%;
    height: 400px;
    background-color: #ededed;
    margin: 0 auto;
    margin-top: 40px;
  }

  .bottonBg{
    width: 90%;
    height: 150px;
    display: block;
    margin: 0 auto;
    background-color: #FFFFFF;
    position: relative;
  }

  .button {
    font-family: 'PuHuiTi';
    width: 280px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    background-color: #3862ff;
    color: #ffffff;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    margin-left: -140px;
    top: 40px;
  }
  .button_1{
    font-family: 'PuHuiTi';
    width: 280px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    background-color: #3862ff;
    color: #ffffff;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    margin-left: -140px;
    top: 40px;
  }
}

.area {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;

  .main {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.content_bg {
  width: 90%;
  display: block;
  margin: 0 auto;
}

.in{
  width: 100%;
  height: 160px;
  background-image: url('https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2024-4-29/bg.png');
  background-size: 100%;
  box-sizing: border-box;
  background-color: #dae2ff;
}


</style>